@import './variables.scss';

$size: 25px ;
$top-height: calc($size / 3.6) ;
$font-size: calc($size - $top-height);
$check-height: calc($size / 1.2);
$check-indent: calc($size / 8);

$size-sm: 20px;
$top-height-sm: calc($size-sm/3.6);
$font-size-sm: calc($size-sm - $top-height-sm);
$check-height-sm: calc($size-sm / 1.4);
$check-indent-sm: calc($size-sm / 10);

$size-bg: 50px;
$top-height-bg: calc($size-bg/3.6);
$font-size-bg: calc($size-bg - $top-height-bg);
$check-height-bg: calc($size-bg / 1.2);
$check-indent-bg: calc($size-bg / 6);


// CHECKBOX

  @keyframes click-wave {
    0% {
      height: $size;
      width: $size ;
      opacity: 0.35 ;
      position: relative;
    }
    100% {
      height: calc($size*2) ;
      width: calc($size*2);
      margin-left: -$size*0.666666;
      margin-top:  -$size*0.666666;
      opacity: 0 ;
    }
  }

  .option-input {
    -webkit-appearance: none;
    -moz-appearance: none ;
    -ms-appearance: none ;
    -o-appearance: none ;
    appearance: none;
    position: relative !important;
    top: $top-height;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    height: $size !important;
    width: $size !important;
    transition: all 0.15s ease-out 0s !important;
    background: $light !important;
    border: 1.8px solid !important;
    border-color: $dark !important;
    color: #fff !important;
    cursor: pointer !important;
    display: inline-block !important;
    margin-right: 0.5rem !important;
    outline: none !important;
    z-index: 900 !important;
    padding: 0px !important;
    border-radius: 0px !important;
  }
  .option-input:hover {
    background: $secondary !important;
  }
  .option-input:checked {
    background: $secondary !important;
  }
  .option-input:checked::before {
    height: $size !important;
    width: $size !important;
    position: absolute !important;
    content: "✔" !important;
    display: inline-block !important;
    font-size: $font-size !important;
    line-height: $check-height !important;
    text-indent: $check-indent !important;
  }
  .option-input:checked::after {
    -webkit-animation: click-wave 0.65s ;
    -moz-animation: click-wave 0.65s ;
    animation: click-wave 0.65s;
    background: $primary !important;
    content: "" !important;
    display: block !important;
    position: relative !important;
    z-index: 100 !important;
  }

  .option-input:disabled {
    color: rgb(170, 170, 170) !important;
    border-color: rgb(170, 170, 170) !important;
    background-color: white !important;
    cursor: default !important;
  }


  @keyframes click-wave-sm {
    0% {
      height: $size-sm ;
      width: $size-sm;
      opacity: 0.35;
      position: relative ;
    }
    100% {
      height: calc($size-sm*2) ;
      width: calc($size-sm*2);
      margin-left: -$size-sm*0.666666 ;
      margin-top: -$size-sm*0.666666;
      opacity: 0 ;
    }
  }

  .option-input-sm {
    -webkit-appearance: none ;
    -moz-appearance: none ;
    -ms-appearance: none ;
    -o-appearance: none ;
    appearance: none ;
    position: relative !important;
    top: $top-height-sm !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    height: $size-sm !important;
    width: $size-sm !important;
    transition: all 0.15s ease-out 0s !important;
    background: $light !important;
    border: 1.6px solid !important;
    border-color: $dark !important;
    color: #fff !important;
    cursor: pointer !important;
    display: inline-block !important;
    margin-right: 0.5rem !important;
    outline: none !important;
    z-index: 900 !important;
    padding-top: 3px !important;
    padding-left: 1px !important;
    border-radius: 0px !important;
  }
  .option-input-sm:hover {
    background: $secondary !important;
  }
  .option-input-sm:checked {
    background: $secondary !important;
  }
  .option-input-sm:checked::before {
    height: $size-sm !important;
    width: $size-sm !important;
    position: absolute !important;
    content: "✔" !important;
    display: inline-block !important;
    font-size: $font-size-sm !important;
    line-height: $check-height-sm !important;
    text-indent: $check-indent-sm !important;
  }
  .option-input-sm:checked::after {
    -webkit-animation: click-wave-sm 0.65s ;
    -moz-animation: click-wave-sm 0.65s ;
    animation: click-wave-sm 0.65s ;
    background: $primary !important;
    content: "" !important;
    display: block !important;
    position: relative !important;
    z-index: 100 !important;
  }

  .option-input-sm:disabled {
    color: rgb(170, 170, 170) !important;
    border-color: rgb(170, 170, 170) !important;
    background-color: white !important;
    cursor: default !important;
  }

  @keyframes click-wave-bg {
    0% {
      height: $size-bg;
      width: $size-bg;
      opacity: 0.35;
      position: relative ;
    }
    100% {
      height: calc($size-bg*2);
      width: calc($size-bg*2) ;
      margin-left: -$size-bg*0.566666 ;
      margin-top:  -$size-bg*0.566666 ;
      opacity: 0;
    }
  }

  .option-input-bg {
    -webkit-appearance: none ;
    -moz-appearance: none ;
    -ms-appearance: none ;
    -o-appearance: none ;
    appearance: none ;
    position: relative !important;
    top: $top-height-bg !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    height: $size-bg !important;
    width: $size-bg !important;
    transition: all 0.15s ease-out 0s !important;
    background: $light !important;
    border: solid !important;
    border-color: $dark !important;
    color: #fff !important;
    cursor: pointer !important;
    display: inline-block !important;
    margin-right: 0.5rem !important;
    outline: none !important;
    z-index: 900 !important;
    padding: 0px !important;
    border-radius: 0px !important;
  }
  .option-input-bg:hover {
    background: $secondary !important;
  }
  .option-input-bg:checked {
    background: $secondary !important;
  }
  .option-input-bg:checked::before {
    height: $size-bg !important;
    width: $size-bg !important;
    position: absolute !important;
    content: "✔" !important;
    display: inline-block !important;
    font-size: $font-size-bg !important;
    line-height: $check-height-bg !important;
    text-indent: $check-indent-bg !important;
  }
  .option-input-bg:checked::after {
    -webkit-animation: click-wave-bg 0.65s;
    -moz-animation: click-wave-bg 0.65s ;
    animation: click-wave-bg 0.65s ;
    background: $primary !important;
    content: "" !important;
    display: block !important;
    position: relative !important;
    z-index: 100 !important;
  }

  .option-input-bg:disabled {
    color: rgb(170, 170, 170) !important;
    border-color: rgb(170, 170, 170) !important;
    background-color: white !important;
    cursor: default !important;
  }


  // RADIO

    $radio-size: 20px ;
    $radio-top-height: calc($radio-size / 3.6) ;
    $radio-font-size: calc( $radio-size * 1.6) ;
    $radio-circle-height: calc($radio-size * 0.55) ;
    $radio-circle-indent: calc($radio-size / 10) ;

  @keyframes click-wave-radio {
    0% {
      height: $radio-size;
      width: $radio-size ;
      opacity: 0.35 ;
      position: relative ;
      margin-left: calc($radio-size / -10);
      margin-top: -$radio-size*0.2 ;
    }
    100% {
      height: calc($radio-size*2) ;
      width: calc($radio-size*2) ;
      margin-left: -$radio-size*0.7;
      margin-top: $radio-size*0.7;
      opacity: 0 ;
    }
  }

  .option-input-radio.radio {
    border-radius: 50% !important;
  }
  .option-input-radio.radio::after {
    border-radius: 50% !important;
  }

  .option-input-radio {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    -o-appearance: none !important;
    appearance: none !important;
    position: relative !important;
    top: $radio-top-height !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    height: $radio-size !important;
    width: $radio-size !important;
    transition: all 0.15s ease-out 0s !important;
    background: $light !important;
    border: 1.5px solid !important;
    border-color: $dark !important;
    color: #fff !important;
    cursor: pointer !important;
    display: inline-block !important;
    margin-right: 0.5rem !important;
    outline: none !important;
    z-index: 900 !important;
    padding-top: 2.05px !important;
    padding-left: 1.5px !important;
  }
  .option-input-radio:checked::before {
    content: "•" !important;
    position: absolute !important;
    display: inline-block !important;
    font-size: $radio-font-size !important;
    color: $primary !important;
    margin-top: -16px !important;
    margin-left: $radio-circle-indent !important;
  }

  .option-input-radio:checked::after {
    -webkit-animation: click-wave-radio 0.65s ;
    -moz-animation: click-wave-radio 0.65s ;
    animation: click-wave-radio 0.65s;
    background: $dark !important;
    content: "" !important;
    display: block !important;
    position: relative !important;
    z-index: 100 !important;
  }

  .option-input-radio:hover {
    background: $secondary !important;
  }
  .option-input-radio:checked {
    background: $secondary !important;
  }














  .option-input-sm.radio {
    border-radius: 50% !important;
  }
  .option-input-sm.radio::after {
    border-radius: 50% !important;
  }

  .option-input-bg.radio {
    border-radius: 50% !important;
  }
  .option-input-bg.radio::after {
    border-radius: 50% !important;
  }



  .checkbox-loading {
    display: inline-block;
    width: $size-sm;
    height: $size-sm;
    position: relative;
    border: 1.8px solid $dark;
    top: 50%;
    animation: loader 4s infinite ease;
    }

    .checkbox-loading-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: $secondary;
    animation: loader-inner 4s infinite ease-in;
    }

    @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(180deg);
    }

    50% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(360deg);
    }

    100% {
      transform: rotate(360deg);
    }
    }

    @keyframes loader-inner {
    0% {
      height: 0%;
    }

    25% {
      height: 0%;
    }

    50% {
      height: 100%;
    }

    75% {
      height: 100%;
    }

    100% {
      height: 0%;
    }
    }


    .checkbox-loading-position {
      position:relative;
       top:3px;
    }
