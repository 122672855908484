.loading-indicator {
  display: inline-block;
  position: relative;
  width: 80px;
  padding-top: 20px;
}
.loading-indicator div {
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading-indicator div:nth-child(1) {
  left: 8px;
  animation: loading-indicator1 0.6s infinite;
}
.loading-indicator div:nth-child(2) {
  left: 8px;
  animation: loading-indicator2 0.6s infinite;
}
.loading-indicator div:nth-child(3) {
  left: 32px;
  animation: loading-indicator2 0.6s infinite;
}
.loading-indicator div:nth-child(4) {
  left: 56px;
  animation: loading-indicator3 0.6s infinite;
}
@keyframes loading-indicator1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loading-indicator3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loading-indicator2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/*
<div class="loading-indicator"><div></div><div></div><div></div><div></div></div> */
