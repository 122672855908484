
.logs-table {
  border-collapse: collapse;
  width: 100%;
  //table-layout:fixed;
  }

.logs-table td, .logs-table th {
  border: 1px solid $secondary;
  padding: 4px 8px;
}

.logs-table tr:nth-child(even){background-color: #f2f2f2;}

.logs-table tr:hover {background-color: $ocean-blue-light;}

.logs-table th {
  padding: 12px 10px;
  text-align: left;
  background-color: $primary;
  color: $light;

  //float table header on scroll
  position:sticky;
  top: 0;

}


td, th {
  width: auto;
}

td.min, th.min {
  width: 1%;
  white-space: nowrap;
}
